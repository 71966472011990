import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { InternalPage } from "components/InternalPage"
import { RelatedTeasers } from "../components/RelatedTeasers"

const ArticlePage = ({ pageContext }) => {
  const {
    title,
    content,
    featuredImage,
    seo,
    author,
    date,
    categories,
    ArticleTeasersBlock,
    previewSQ,
    depth,
    readingTime,
  } = pageContext
  const teasersData = ArticleTeasersBlock?.articleTeasersBlock

  return (
    <Layout
      ancestors={[{ title: "Expert News & Views", link: "/expert-news-views/" }]}
      internal
      headerTitle={title}
      headerImg={featuredImage}
      preview={previewSQ}
      depth={depth}
    >
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={featuredImage?.sourceUrl}
      />
      <InternalPage
        content={content}
        article
        author={author}
        date={date}
        category={categories?.nodes[0]}
        readingTime={readingTime}
      />

      {teasersData?.teaserblockitems?.length ? (
        <RelatedTeasers article content={content} data={teasersData} />
      ) : null}
    </Layout>
  )
}

export default ArticlePage
